import {
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
  type MetaFunction,
  redirect,
} from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';
import omit from 'lodash/omit';
import {
  type IsEmailValidQueryVariables,
  type RegisterInput,
  type RequestNewAreaInput,
  activitiesQuery,
  areasQuery,
  isEmailValidQuery,
  publicActivitiesQuery,
  registerMutation,
  requestNewAreaMutation,
  suggestedActivitiesQuery,
} from '~/generated/server';
import { getViewerSession, setViewerSession } from '~/utils/auth.server';
import { connectionResultToNodeArray } from '~/utils/connection-result-to-node-array';
import { TypedFormData } from '~/utils/form-data';
import { handleActionDataWithMessage } from '~/utils/handle-action.server';
import { getHomePageFaq } from '~/utils/intercom.server';
import { mergeMeta } from '~/utils/merge-meta';
import { getMeta } from '~/utils/meta';
import { namedAction } from '~/utils/named-action.server';
import { computePage } from '~/utils/pagination.server';
import { leadSourceCookie, sessionStorage } from '~/utils/session.server';
import PrivateContent from './PrivateContent';
import PublicContent from './PublicContent';
import { fetchBlogPosts } from './utils.server';

export const handle = {
  i18n: 'home',
};

export const meta: MetaFunction<typeof loader> = mergeMeta(() =>
  getMeta([
    {
      title: "Rejoignez le club d'activités préféré des seniors - Club Colette",
    },
    {
      name: 'description',
      content:
        'Participez à des activités et des sorties près de chez vous | Accès gratuit et sans engagement | Faites des rencontres grâce au Club Colette 💛',
    },
  ]),
);

export async function action(ctx: ActionFunctionArgs) {
  return await namedAction(ctx.request, {
    async register() {
      const fd = await new TypedFormData<RegisterInput>().init(ctx.request);
      const leadSource = await leadSourceCookie.parse(ctx.request.headers.get('Cookie'));

      const response = await registerMutation(ctx, {
        input: {
          firstName: fd.get('firstName'),
          lastName: fd.get('lastName'),
          email: fd.get('email'),
          area: fd.get('area'),
          leadSource: leadSource || undefined,
        },
      });

      const session = await setViewerSession(ctx.request, {
        ...response.register.viewer,
        token: response.register.token,
      });

      const headers = new Headers();
      headers.append('Set-Cookie', await sessionStorage.commitSession(session));
      if (leadSource) {
        headers.append('Set-Cookie', await leadSourceCookie.serialize('', { maxAge: 1 }));
      }

      return redirect('/signup/password', {
        headers,
      });
    },

    // @ts-expect-error - singlefetch
    async requestNewArea() {
      const fd = await new TypedFormData<RequestNewAreaInput>().init(ctx.request);

      const response = await requestNewAreaMutation(ctx, {
        input: {
          email: fd.get('email'),
          postalCode: fd.get('postalCode'),
        },
      });

      return await handleActionDataWithMessage(
        response.requestNewArea,
        'Votre demande a été envoyée.',
      );
    },

    // @ts-expect-error single fetch
    async validateEmail() {
      const fd = await new TypedFormData<IsEmailValidQueryVariables>().init(ctx.request);

      const response = await isEmailValidQuery(ctx, { email: fd.get('email') });

      return {
        emailValidity: response.isEmailValid,
        email: fd.get('email'),
      };
    },
  });
}

/**
 * SEO
 *
 * FAQ ? https://developers.google.com/search/docs/appearance/structured-data/faqpage
 * Image: https://developers.google.com/search/docs/appearance/structured-data/image-license-metadata
 */

export async function loader(ctx: LoaderFunctionArgs) {
  const viewer = await getViewerSession(ctx.request);

  if (viewer) {
    const [{ activities }, { activities: lunches }, { suggestedActivities }] = await Promise.all([
      activitiesQuery(ctx, {
        first: 3,
        filter: {
          registeredTo: true,
          upcomingOrOngoing: true,
          types: ['ACTIVITY', 'LUNCH_TABLE'],
        },
      }),
      activitiesQuery(ctx, {
        first: 3,
        filter: {
          registeredTo: true,
          upcomingOrOngoing: true,
          types: ['LUNCH'],
        },
      }),
      suggestedActivitiesQuery(ctx, { first: 3 }),
    ]);

    return {
      viewer: omit(viewer, 'token'),
      myActivities: connectionResultToNodeArray(activities),
      myLunches: connectionResultToNodeArray(lunches),
      activities: activitiesQuery(ctx, {
        ...computePage(ctx.request, 12),
        filter: {
          upcomingOrOngoing: true,
        },
      }),
      suggestedActivities: connectionResultToNodeArray(suggestedActivities),
      blogPosts: undefined,
      faq: undefined,
    };
  }

  return {
    viewer: undefined,
    areas: areasQuery(ctx),
    blogPosts: fetchBlogPosts(),
    activities: publicActivitiesQuery(ctx, {
      first: 3,
      filter: { upcomingOrOngoing: true },
    }),
    faq: (await getHomePageFaq()) as any,
    myActivities: undefined,
    myLunches: undefined,
    suggestedActivities: undefined,
  };
}

export default function HomeRoute() {
  const data = useLoaderData<typeof loader>();
  return data.viewer ? <PrivateContent data={data} /> : <PublicContent data={data} />;
}
