import { Await, Link } from '@remix-run/react';
import dayjs from 'dayjs';
import React from 'react';
import {
  ActivityCard,
  ActivityPlaceholder,
  ActivityPlaceholderContent,
  ActivityPlaceholderImage,
} from '~/components/ActivityCard';
import { AwaitError } from '~/components/AwaitError';
import { buttonVariants } from '~/components/ui/Button';
import { Pagination, PaginationContent, PaginationItems } from '~/components/ui/Pagination';
import { Skeleton } from '~/components/ui/Skeleton';
import { HeadingL, HeadingM, HeadingXXL, textVariants } from '~/components/ui/Typography';
import { useTranslation } from '~/i18n';
import { connectionResultToNodeArray } from '~/utils/connection-result-to-node-array';
import { EmptyLunchState } from '../_app.my-lunches';
import type { loader } from './route';

type Loader = Awaited<ReturnType<typeof loader>>;

interface Props {
  data: Extract<Loader, { blogPosts: undefined }>;
}

export default function PrivateContent({ data }: Props) {
  const { t } = useTranslation('home');

  return (
    <main className="divide-y divide-border">
      <div className="mx-auto max-w-screen-xl px-4 py-12 lg:px-0">
        <section className="mb-9 space-y-4">
          <p
            className={textVariants({
              size: 'xl',
              class: 'font-bold capitalize',
            })}
          >
            {dayjs().format('dddd D MMMM YYYY')}
          </p>
          <HeadingXXL>{t('welcome', { firstname: data.viewer.profile.firstName })}</HeadingXXL>
        </section>

        {data.myActivities?.length ? (
          <section className="space-y-8 pb-12">
            <div className="flex flex-col items-center gap-y-4 md:flex-row md:justify-between">
              <HeadingL>{t('my-activities')}</HeadingL>

              <Link
                prefetch="intent"
                to="/my-activities"
                className={buttonVariants({
                  variant: 'tertiary',
                  class: 'w-auto hidden lg:flex',
                })}
              >
                {t('all-my-activities')}
              </Link>
            </div>
            <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 md:gap-6">
              {data.myActivities.map((activity) => (
                <ActivityCard key={activity.id} activity={activity} />
              ))}
            </div>
            <Link
              prefetch="intent"
              to="/my-activities"
              className={buttonVariants({
                variant: 'tertiary',
                class: 'w-full lg:hidden',
              })}
            >
              {t('all-my-activities')}
            </Link>
          </section>
        ) : (
          <section className="space-y-8 pb-12">
            <div className="flex flex-col items-center gap-y-4 md:flex-row md:justify-between">
              <HeadingL>{t('no-upcoming-activities')}</HeadingL>
              <Link
                to="/my-activities"
                className={buttonVariants({
                  variant: 'tertiary',
                  class: 'w-full md:w-auto',
                })}
                prefetch="intent"
              >
                {t('all-my-activities')}
              </Link>
            </div>

            <div className="space-y-8 rounded-2xl bg-surface-blue p-8">
              <HeadingM className="font-medium">{t('you-might-like')}</HeadingM>
              <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 md:gap-6">
                {data.suggestedActivities.map((activity) => (
                  <ActivityCard key={activity.id} activity={activity} canRegister />
                ))}
              </div>
            </div>
          </section>
        )}

        <section className="space-y-8 pb-12">
          <div className="flex flex-col items-center gap-y-4 md:flex-row md:justify-between">
            <HeadingL>{t('my-lunches')}</HeadingL>

            <Link
              prefetch="intent"
              to="/my-lunches"
              className={buttonVariants({
                variant: 'tertiary',
                class: 'w-auto hidden lg:flex',
              })}
            >
              {t('all-my-lunches')}
            </Link>
          </div>
          {data.myLunches.length ? (
            <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 md:gap-6">
              {data.myLunches.map((lunch) => (
                <ActivityCard key={lunch.id} activity={lunch} />
              ))}
            </div>
          ) : (
            <EmptyLunchState />
          )}

          <Link
            prefetch="intent"
            to="/my-lunches"
            className={buttonVariants({
              variant: 'tertiary',
              class: 'w-full lg:hidden',
            })}
          >
            {t('all-my-lunches')}
          </Link>
        </section>

        {data.myActivities.length && data.suggestedActivities.length ? (
          <section className="space-y-8 py-12">
            <HeadingL>{t('suggested-activities')}</HeadingL>

            <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 md:gap-6">
              {data.suggestedActivities.map((activity) => (
                <ActivityCard key={activity.id} activity={activity} canRegister />
              ))}
            </div>
          </section>
        ) : null}
      </div>

      <div className="mx-auto max-w-screen-xl px-4 py-12 md:px-0">
        <section className="pt-12">
          <HeadingL>{t('all-activities')}</HeadingL>
          <React.Suspense
            fallback={
              <div className="my-10 grid grid-cols-[repeat(auto-fill,_minmax(300px,_1fr))] gap-4 md:gap-6">
                {Array.from({ length: 8 }, (_, i) => (
                  <ActivityPlaceholder key={i}>
                    <ActivityPlaceholderImage />
                    <ActivityPlaceholderContent>
                      <div className="space-y-1">
                        <Skeleton className="h-6 w-full" />
                        <Skeleton className="h-6 w-1/3" />
                      </div>
                      <div className="space-y-2">
                        <Skeleton className="h-4 w-full" />
                        <Skeleton className="h-4 w-1/3" />
                      </div>
                      <Skeleton className="h-6 w-1/4" />
                      <div className="flex items-center gap-x-2">
                        <Skeleton className="h-8 w-1/4 rounded-full" />
                        <Skeleton className="h-8 w-1/3 rounded-full" />
                      </div>
                    </ActivityPlaceholderContent>
                  </ActivityPlaceholder>
                ))}
              </div>
            }
          >
            <Await resolve={data.activities} errorElement={<AwaitError />}>
              {({ activities }) => {
                return (
                  <>
                    <div className="my-10 grid grid-cols-[repeat(auto-fill,_minmax(300px,_1fr))] gap-4 md:gap-6">
                      {connectionResultToNodeArray(activities).map((activity) => (
                        <ActivityCard key={activity.id} activity={activity} />
                      ))}
                    </div>
                    <Pagination>
                      <PaginationContent>
                        <PaginationItems perPage={12} count={activities.count || 0} />
                      </PaginationContent>
                    </Pagination>
                  </>
                );
              }}
            </Await>
          </React.Suspense>
        </section>
      </div>
    </main>
  );
}
